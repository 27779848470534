.navbar-brand img {
	height: 50px;
}
.sidebar-btns {
	opacity: 0;
}
.logout-div{
    display: flex;
    align-items: center;
    justify-content: space-around !important;
	color: white;
}
.logout-name{
    margin-right: 10px;
}
@media only screen and (max-width: 900px) {
	button {
		opacity: 1;
	}
}
