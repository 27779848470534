.login-page {
	overflow: hidden;
	.login-page-left-bg {
		background-image: url("../../assets/images/ac.jpg");
		width: 100%;

		opacity: 1;
		.login-page-left-col {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 102%;
			height: 100vh;
			background: linear-gradient(
				153.66deg,
				rgba(47, 100, 235, 0.9) 0%,
				rgba(149, 179, 255, 0.9) 105.27%
			);
		}
	}

	.loginform {
		z-index: 10;
		margin: auto;
		width: 340px;
	}

	label {
		color: #000000;
		font-size: 18px;
	}

	h2 {
		text-align: center;
		font-weight: bold;
		color: #2f64eb;
		font-size: 30px;
		margin-bottom: 2rem;
	}

	.login-btn {
		background: #2f64eb;
		border-radius: 5px;
		width: 100%;
		border-color: #ffffff !important;
	}

	.forgot-pwd {
		font-size: small;
		color: white;
	}

	.loading-overlay {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
		z-index: 2; /* incase there is a display stack */
		cursor: pointer;

		display: flex;
		gap: 1em;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.show-password{
        cursor: pointer;
        font-size: small;
        color: #2f64eb;
    }

    .ms-auth-button {
	    background-color: transparent;
	    color: black;
	    font-weight: bold;
	    border-radius: 0.5rem;
	    border: 0.1rem solid grey;
	    outline: none;
	    padding: 0.6rem 1rem;
    }

    .ms-auth-button img {
	    margin-right: 1rem;
    }
}
