[role="listbox"] {
  min-height: 18em;
  padding: 10px;
  background: white;
  border: 1px solid #aaa;
}

[role="option"] {
  display: block;
  padding: 0 1em 0 1.5em;
  position: relative;
  line-height: 1.8em;
}

[role="option"].focused {
  background: #bde4ff;
}

[role="option"][aria-selected="true"]::before {
  position: absolute;
  left: 0.5em;
}